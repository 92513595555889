import { MutableRefObject } from 'react';
// Types
import { ColorPickerOption } from 'ui2/ColorPicker/ColorPicker';
import { GetMerchProducts_getMerchProducts_entities_merchProductVariants } from 'api/merch/types/GetMerchProducts';
import {
  GetPRProductByTypeV2_getPRProductByTypeV2,
  GetPRProductByTypeV2_getPRProductByTypeV2_data_templates_templates,
  GetPRProductByTypeV2_getPRProductByTypeV2_data_product_variants,
  GetPRProductByTypeV2,
} from 'api/merch/types/GetPRProductByTypeV2';
import { GetMerchProduct_getMerchProduct_merchProductVariants } from 'api/merch/types/GetMerchProduct';
import { GetMyMerchProductV2_getMerchProduct_merchProductVariants } from 'api/merch/types/GetMyMerchProductV2';
import {
  Age,
  AuctionStatus,
  Gender,
  HashtagInput,
  MerchCustomImageV2Input,
  MerchOrderStatus,
  MerchProductCustomImageSetInput,
  MerchProductPrintFileSetInput,
  MerchProductVariantSet,
  MerchType,
  PlacementTypes,
  VariantPrintFileInput,
} from 'api/graphql-global-types';
import { DesignRequests_designRequests_entities_designCreationDetails_merchItems_merch_merchProductVariants } from 'api/designLab/types/DesignRequests';
import {
  BuildData,
  CustomImage,
  PrintCoordinates,
  PrintFiles,
  SelectedPrintImages,
} from 'components/ManageMerch/CreateEditMerch/MerchConstructor/MerchConstructor';
import { PresignedUrlResultItem } from './single-uploader';
import { GetMyPrintImages_getMyPrintImages } from 'api/merch/types/GetMyPrintImages';
import { GetStoreMerchProduct_getStoreMerchProduct_merchProductVariants } from 'api/merch/types/GetStoreMerchProduct';

type MerchOptions = {
  sizes: Set<string>;
  colors: Set<string>;
};

type VariantData = {
  size: string;
  gender: string;
  color: string;
  variants:
    | GetStoreMerchProduct_getStoreMerchProduct_merchProductVariants[]
    | undefined;
  productType?: MerchType | undefined;
};

type SimilarVariant = {
  size: string;
  color: string;
};

type GetProductColor = {
  color: string;
  colorCode: string;
  colorCode2: string | null;
};

type GetProductColors = GetProductColor[];

type PRProductColor = {
  id: number;
  color: string;
  color2: string | null;
  title: string;
  price: string | null;
  image: string | null;
};

type PrintCanvasDimensions = {
  templateWidth: number;
  templateHeight: number;
  printAreaWidth: number;
  printAreaHeight: number;
  printAreaTop: number;
  printAreaLeft: number;
  scaleFactorX: number;
  scaleFactorY: number;
  originTemplateWidth: number;
  originTemplateHeight: number;
  originPrintAreaWidth: number;
  originPrintAreaHeight: number;
  originPrintAreaTop: number;
  originPrintAreaLeft: number;
};

type PrintImageDimensions = {
  angle: number;
  top: number;
  left: number;
  scaleX: number;
  scaleY: number;
  width: number;
  height: number;
  rotatedLeft: number;
  rotatedTop: number;
};

type MerchProductVariantsType =
  | GetMerchProducts_getMerchProducts_entities_merchProductVariants
  | GetMerchProduct_getMerchProduct_merchProductVariants
  | GetStoreMerchProduct_getStoreMerchProduct_merchProductVariants;

const genders = ['Male', 'Female', 'Unisex'] as const;

const sizes = [
  'XXS',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  '2XL',
  '3XL',
  '4XL',
  '5XL',
  'One size', // for hat type
] as const;

export const getMerchGenders = (
  variants:
    | GetStoreMerchProduct_getStoreMerchProduct_merchProductVariants[]
    | undefined
): Set<string> => {
  const result = new Set<string>();

  genders.forEach((gender) => {
    const variant = variants?.find((variant) => variant.gender === gender);

    if (variant) {
      result.add(gender);
    }
  });

  return result;
};

export const getMerchSizes = (
  variants:
    | GetStoreMerchProduct_getStoreMerchProduct_merchProductVariants[]
    | undefined
): Set<string> => {
  const result = new Set<string>();

  sizes.forEach((size) => {
    const variant = variants?.find((variant) => variant.size === size);

    if (variant) {
      result.add(size);
    }
  });

  return result;
};

export const getColorName = (
  variant:
    | GetStoreMerchProduct_getStoreMerchProduct_merchProductVariants
    | GetStoreMerchProduct_getStoreMerchProduct_merchProductVariants
): string => {
  return `${variant.color}-${variant.colorGroupId}`;
};

export const getMerchColors = (
  variants:
    | GetStoreMerchProduct_getStoreMerchProduct_merchProductVariants[]
    | undefined
): Set<string> => {
  const result = new Set<string>();

  variants?.forEach((variant) => {
    result.add(getColorName(variant));
  });

  return result;
};

export const getLowestPriceVariant = (
  variants:
    | GetStoreMerchProduct_getStoreMerchProduct_merchProductVariants[]
    | undefined,
  type: MerchType | undefined
):
  | GetStoreMerchProduct_getStoreMerchProduct_merchProductVariants
  | undefined => {
  if (!variants || variants.length === 0) return undefined;

  // Create a map to check if a size is available
  const availableSizes = new Set(variants.map((variant) => variant.size));

  // Find the first available size according to the priority list
  const firstAvailableSize = sizes.find((size) => availableSizes.has(size));

  let filteredVariants;

  // This part is covering the case when Printful is returning null for hat size value
  if (!firstAvailableSize) {
    if (type === MerchType.Hat) {
      filteredVariants = variants;
    } else {
      return undefined;
    }
  }

  if (firstAvailableSize) {
    // Filter variants to include only the first available size
    filteredVariants = variants.filter(
      (variant) => variant.size === firstAvailableSize
    );
  }

  // Sort filtered variants by price
  filteredVariants.sort((a, b) => a.price - b.price);

  // Since 'Male' has the highest priority, followed by 'Female', and 'Unisex' has the lowest priority,
  // we prioritize the first occurrence of 'Male', then 'Female', and finally 'Unisex' if available
  const result =
    filteredVariants.find((variant) => variant.gender === Gender.Male) ||
    filteredVariants.find((variant) => variant.gender === Gender.Female) ||
    filteredVariants[0];

  return result;
};

export const getAllMerchOptions = ({
  size,
  gender,
  color,
  variants,
}: VariantData): MerchOptions => {
  const sizeOptions = variants?.filter((variant) => {
    return (
      variant.gender === gender &&
      `${variant.color}-${variant.colorGroupId}` === color
    );
  });
  const sizes: Set<string> = getMerchSizes(sizeOptions);

  const colorOptions = variants?.filter((variant) => {
    return variant.gender === gender && variant.size === size;
  });
  const colors: Set<string> = getMerchColors(colorOptions);

  return { sizes, colors };
};

export const getTotalMerchOptions = ({
  gender,
  variants,
}: VariantData): MerchOptions => {
  const sizeOptions = variants?.filter((variant) => {
    return variant.gender === gender;
  });
  const sizes: Set<string> = getMerchSizes(sizeOptions);

  const colorOptions = variants?.filter((variant) => {
    return variant.gender === gender;
  });
  const colors: Set<string> = getMerchColors(colorOptions);

  return { sizes, colors };
};

export const getSelectedVariant = ({
  size,
  gender,
  color,
  variants,
  productType,
}: VariantData):
  | GetStoreMerchProduct_getStoreMerchProduct_merchProductVariants
  | undefined => {
  const result = variants?.find((variant) => {
    if (!variant.size && productType === MerchType.Hat) {
      return variant.gender === gender && getColorName(variant) === color;
    } else {
      return (
        variant.size === size &&
        variant.gender === gender &&
        getColorName(variant) === color
      );
    }
  });

  return result;
};

export const getSimilarVariant = ({
  size,
  gender,
  color,
  variants,
}: VariantData): SimilarVariant => {
  const result: SimilarVariant = {
    size,
    color,
  };

  // perfectVariant is an exact match by all properties(gender, size, color)
  // this variant's size and color are already set on initiation
  const noPerfectVariant = !variants?.find(
    (variant) =>
      variant.gender === gender &&
      variant.size === size &&
      getColorName(variant) === color
  );

  if (noPerfectVariant) {
    // if there is no perfect variant, find out if there is a variant with same size
    // size is already set on initiation, so it only changes if there is no match
    const sizeVariant = variants?.find(
      (variant) => variant.gender === gender && variant.size === size
    );
    if (!sizeVariant) {
      // default size is L for all the merch except hats-but they never trigger this function
      result.size = 'L';
    }

    // try to find a match using the determined size
    const colorVariant = variants?.find(
      (variant) =>
        variant.gender === gender &&
        variant.size === result.size &&
        getColorName(variant) === color
    );

    // find a variant with new gender and size
    const genderAndSizeVariant = variants?.find((variant) => {
      variant.gender === gender && variant.size === result.size;
    });

    if (!colorVariant && !!genderAndSizeVariant) {
      // if we don't have a new perfect match but we do have a match with size
      result.color = getColorName(genderAndSizeVariant);
    } else if (sizeVariant) {
      // if we have no matches by color but only by size, use that one
      result.color = getColorName(sizeVariant);
    } else {
      // if we have no matches by color or size, use the first match by gender
      // first see if there is one with "L" size, as that is default
      const newGenderVariantWithL = variants?.find(
        (variant) => variant.gender === gender && variant.size === 'L'
      );

      if (newGenderVariantWithL) {
        result.color = getColorName(newGenderVariantWithL);
        result.size = 'L';
      } else {
        // if there is no "L" sized item go with the first one found of the new gender
        const newGenderVariantAny = variants?.find(
          (variant) => variant.gender === gender
        );
        if (newGenderVariantAny && newGenderVariantAny.size) {
          result.color = getColorName(newGenderVariantAny);
          result.size = newGenderVariantAny.size;
        }
      }
    }
  }

  return result;
};

export type Options = {
  [key: string]: GetMerchProduct_getMerchProduct_merchProductVariants & {
    colorOptions: ColorPickerOption[];
    sizeOptions: {
      value: string;
      label: string;
      disabled: boolean;
    }[];
  };
};

export const getMerchGenderOptions = (merchType: MerchType): Gender[] => {
  switch (merchType) {
    case MerchType.Hat:
      return [Gender.Unisex];
    case MerchType.Hoodie:
    case MerchType.Joggers:
    case MerchType.RashGuard: {
      return [Gender.Male, Gender.Female];
    }
    case MerchType.TShirt: {
      return [Gender.Male, Gender.Female, Gender.Youth];
    }
  }
};

export const getMerchOptions = (
  variants: GetMerchProduct_getMerchProduct_merchProductVariants[] | undefined
): Options => {
  const availableColors: {
    [key: string]: {
      color: string;
      colorCode: string;
    };
  } = {};
  const availableSizes = new Set<string>();
  const merchBySize = {};
  const merchByColor = {};

  variants?.forEach((item) => {
    availableColors[item.color] = {
      color: item.color,
      colorCode: item.colorCode,
    };

    if (item.size) {
      availableSizes.add(item.size);
    }
    merchBySize[`${item.size}`] = {
      ...merchBySize[`${item.size}`],
      [item.color]: true,
    };
    merchByColor[`${item.color}`] = {
      ...merchByColor[`${item.color}`],
      ...(item?.size && { [item.size]: true }),
    };
  });

  type SizeOption = {
    value: string;
    label: string;
    disabled: boolean;
  };

  const getMerchSizeOptions = (color: string) => {
    const sizeOptions: SizeOption[] = [];
    const sortedSizeOptions: SizeOption[] = [];

    availableSizes.forEach((item) => {
      sizeOptions.push({
        value: item,
        label: item,
        disabled: !merchBySize[item][color],
      });
    });

    sizes.forEach((size) => {
      const option = sizeOptions.find(
        (sizeOption) => sizeOption.label === size
      );

      if (option) {
        sortedSizeOptions.push(option);
      }
    });

    return sortedSizeOptions;
  };

  const getMerchColorOptions = (size: string | null) => {
    const colorOptions: {
      value: string;
      colorCode: string;
      disabled: boolean;
    }[] = [];

    if (size) {
      Object.keys(availableColors).forEach((key) => {
        colorOptions.push({
          value: availableColors[key].color,
          colorCode: availableColors[key].colorCode,
          disabled: !merchByColor[availableColors[key].color][size],
        });
      });
    }

    return colorOptions;
  };

  return (
    variants?.reduce((acc, value) => {
      acc[`${value.size || ''}-${value.color}`] = {
        ...value,
        colorOptions: getMerchColorOptions(value.size),
        sizeOptions: getMerchSizeOptions(value.color),
      };

      return acc;
    }, {}) || {}
  );
};

export const getProductColors = (
  productVariants:
    | GetMerchProducts_getMerchProducts_entities_merchProductVariants[]
    | DesignRequests_designRequests_entities_designCreationDetails_merchItems_merch_merchProductVariants[]
): GetProductColors => {
  const colorOptions: GetProductColors = [];

  const availableColors: {
    [key: string]: GetProductColor;
  } = {};

  productVariants?.forEach((item) => {
    if (!availableColors[item.color]) {
      const color = {
        color: item.color,
        // fix 'White' color because the "printful" send for us
        // a '#e2e3de'  color which is a wrong and we rewrite it below
        colorCode: item.color === 'White' ? '#fff' : item.colorCode,
        colorCode2: item.colorCode2 || null,
      };

      availableColors[item.color] = color;
      colorOptions.push(color);
    }
  });

  return colorOptions;
};

export type PRProductColors = PRProductColor[];

export const getPRProductsColorsVariants = (
  productByType: GetPRProductByTypeV2_getPRProductByTypeV2 | undefined
): PRProductColors => {
  const uniqColors = new Set<string>();
  const colors: PRProductColors = [];

  productByType?.data?.product?.variants?.forEach((item) => {
    if (
      item?.color &&
      !uniqColors.has(item.color) &&
      item.id &&
      item.color_code
    ) {
      uniqColors.add(item.color);
      colors.push({
        id: item.id,
        // fix 'White' color because the "printful" send for us
        // a '#e2e3de'  color which is a wrong and we rewrite it below
        color: item.color === 'White' ? '#fff' : item.color_code,
        color2: item.color_code2,
        title: item.color,
        price: item.price,
        image: item.image || null,
      });
    }
  });

  return colors;
};

export const findColorByTitle = (
  colorOptions: PRProductColors,
  title: string
) => {
  return colorOptions.find((option) => option.title === title);
};

export const createMerchTitle = (merchType: keyof typeof MerchType): string => {
  switch (merchType) {
    case MerchType.TShirt: {
      return 't-shirts';
    }
    case MerchType.Hoodie: {
      return 'hoodies';
    }
    case MerchType.Hat: {
      return 'Hats';
    }
    case MerchType.Joggers: {
      return 'Joggers';
    }
    case MerchType.RashGuard: {
      return 'compression shirts';
    }
    default: {
      return '';
    }
  }
};

export const getMerchDetails = (
  merchType?: MerchType | null,
  isYouth?: boolean
): string[] => {
  switch (merchType) {
    case MerchType.TShirt:
      if (isYouth) {
        return [
          '100% Airlume combed and ring-spun cotton',
          'Heather colors are 52% combed and ring-spun cotton, 48% polyester',
          'Athletic Heather is 90% combed and ring-spun cotton, 10% polyester',
          'Fabric weight: 4.2 oz/yd² (142 g/m²)',
          'Pre-shrunk fabric',
          '32 singles',
          'Relaxed unisex fit',
          'Side-seamed construction',
          'Blank product sourced from Nicaragua, the US, or Honduras',
        ];
      } else {
        return [
          'Solid colors are 100% combed and ring-spun cotton',
          'Ash color is 99% combed and ring-spun cotton, 1% polyester',
          'Heather colors are 52% combed and ring-spun cotton, 48% polyester',
          'Athletic and Black Heather are 90% combed and ring-spun cotton, 10% polyester',
          'Heather Prism colors are 99% combed and ring-spun cotton, 1% polyester',
          'Fabric weight: 4.2 oz/y² (142 g/m²)',
          'Pre-shrunk fabric',
          '30 single',
          'Tear-away label',
          'Shoulder-to-shoulder taping',
          'Side-seamed',
        ];
      }
    case MerchType.Hoodie: {
      return [
        '50% pre-shrunk cotton, 50% polyester',
        'Fabric weight: 8.0 oz/yd² (271.25 g/m²)',
        'Air-jet spun yarn with a soft feel and reduced pilling',
        'Double-lined hood with matching drawcord',
        'Quarter-turned body to avoid crease down the middle',
        '1 × 1 athletic rib-knit cuffs and waistband with spandex',
        'Front pouch pocket',
        'Double-needle stitched collar, shoulders, armholes, cuffs, and hem',
      ];
    }

    case MerchType.Joggers: {
      return [
        '100% cotton face',
        '65% cotton, 35% polyester',
        'Charcoal Heather is 55% cotton, 45% polyester',
        'Tightly knit 3-end fleece',
        'Fabric weight: 8.5 oz/yd² (288.2 g/m²)',
        '5-thread stitching',
        'Cuffed and side-seamed legs',
        'Elastic inside the waistband',
        'Flat drawstrings in a matching color',
        '2 cross pockets in front',
        '1 top-stitched patch pocket on the back of the right leg',
        'Ribbed waist, cuffs, and gusset at crotch',
        'Blank product sourced from Pakistan',
      ];
    }
    case MerchType.Hat: {
      return [
        '100% cotton twill',
        'Structured, five-panel, high-profile cap',
        'Green undervisor',
        'Sewn eyelets',
        'Snapback closure',
        'Head circumference: 21⅝″–23⅝″',
      ];
    }
    case MerchType.RashGuard: {
      return [
        '82% polyester, 18% spandex',
        'Fabric weight: 6.78 oz/yd² (230 g/m²), weight may vary by 5%',
        'Very soft four-way stretch fabric that stretches and recovers on the cross and lengthwise grains',
        'UPF 50+',
        'Comfortable longer body and sleeves',
        'Flat seam and coverstitch',
        'Printed, cut, and hand-sewn by our expert in-house team',
        'Blank product components sourced from China',
        'Slim fit',
      ];
    }
    default: {
      return [];
    }
  }
};

export const getMerchShippingDetails = () => {
  return [
    'All sales are final, no returns. ',
    'Please note that international orders may incur additional duties, taxes, and fees. ',
  ];
};

export const computePrintCanvasDimensions = (
  wrapperRef:
    | ((instance: HTMLDivElement | null) => void)
    | React.MutableRefObject<HTMLDivElement | null>
    | null,
  template:
    | GetPRProductByTypeV2_getPRProductByTypeV2_data_templates_templates
    | null
    | undefined
): PrintCanvasDimensions | null => {
  if (
    wrapperRef &&
    template?.template_width &&
    template?.template_height &&
    template.print_area_width &&
    template.print_area_height &&
    template.print_area_left
  ) {
    const imageBlockSizes = (wrapperRef as MutableRefObject<HTMLDivElement | null>)?.current?.getBoundingClientRect();
    const boxWidth = imageBlockSizes?.width ? imageBlockSizes.width : 0;
    const boxHeight = imageBlockSizes?.height ? imageBlockSizes.height : 0;
    const scaleFactorX = boxWidth / template.template_width;
    const scaleFactorY = boxHeight / template.template_height;
    const templateWidth = template.template_width * scaleFactorX;
    const templateHeight = template.template_height * scaleFactorY;
    const printAreaWidth = template.print_area_width * scaleFactorX;
    const printAreaHeight = template.print_area_height * scaleFactorY;
    const printAreaTop = template.print_area_top
      ? template.print_area_top * scaleFactorY
      : 1 * scaleFactorY;
    const printAreaLeft = template.print_area_left * scaleFactorX;

    return {
      scaleFactorX,
      scaleFactorY,
      templateWidth,
      templateHeight,
      printAreaWidth,
      printAreaHeight,
      printAreaTop,
      printAreaLeft,
      originTemplateWidth: template.template_width,
      originTemplateHeight: template.template_height,
      originPrintAreaWidth: template.print_area_width,
      originPrintAreaHeight: template.print_area_height,
      originPrintAreaTop: template.print_area_top || 0,
      originPrintAreaLeft: template.print_area_left,
    };
  }

  return null;
};

export type ComputePrintfulDimensions = {
  angle: number;
  top: number;
  left: number;
  width: number;
  height: number;
  scaleX: number;
  scaleY: number;
  rotatedLeft: number;
  rotatedTop: number;
};

export const computePrintfulDimensions = (
  initialCanvasDimensions: PrintCanvasDimensions,
  printImageDimensions: PrintImageDimensions
): ComputePrintfulDimensions => {
  const {
    printAreaTop,
    printAreaLeft,
    scaleFactorX,
    scaleFactorY,
  } = initialCanvasDimensions;
  const {
    top,
    left,
    width,
    height,
    angle,
    scaleX,
    scaleY,
    rotatedLeft,
    rotatedTop,
  } = printImageDimensions;

  return {
    angle: +angle,
    top: +(scaleFactorY
      ? (top - printAreaTop) / scaleFactorY
      : top - printAreaTop),
    left: +(scaleFactorX
      ? (left - printAreaLeft) / scaleFactorX
      : left - printAreaLeft),
    width: +(scaleFactorX ? width / scaleFactorX : width),
    height: +(scaleFactorY ? height / scaleFactorY : height),
    scaleX: +scaleX,
    scaleY: +scaleY,
    rotatedTop: +(scaleFactorY
      ? (rotatedTop - printAreaTop) / scaleFactorY
      : rotatedTop - printAreaTop),
    rotatedLeft: +(scaleFactorX
      ? (rotatedLeft - printAreaLeft) / scaleFactorX
      : rotatedLeft - printAreaLeft),
  };
};

export const calculateAspectRatioFit = (
  srcWidth: number,
  srcHeight: number,
  maxWidth: number,
  maxHeight: number
): { width: number; height: number } => {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

  return { width: srcWidth * ratio, height: srcHeight * ratio };
};

export const hexToRgb = (hex: string): number[] => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return [r, g, b];
};

export type PRProductExtraColors = {
  color: string;
  title: string;
}[];

export const getPRProductsThreads = (
  productByType: GetPRProductByTypeV2_getPRProductByTypeV2 | undefined
): PRProductExtraColors => {
  const colors: PRProductExtraColors = [];
  const threadColors =
    productByType?.data.product.product?.options?.find(
      (item) => item.id === 'thread_colors_front_large'
    )?.values || {};

  Object.keys(threadColors).forEach((key) => {
    colors.push({
      title: threadColors[key],
      color: key,
    });
  });

  return colors;
};

export const getPRProductStitches = (
  productByType: GetPRProductByTypeV2_getPRProductByTypeV2 | undefined
): PRProductExtraColors => {
  const colors: PRProductExtraColors = [];
  const stitchColors =
    productByType?.data.product.product?.options?.find(
      (item) => item.id === 'stitch_color'
    )?.values || {};

  Object.keys(stitchColors).forEach((key) => {
    colors.push({
      title: stitchColors[key],
      color: key,
    });
  });

  return colors;
};

export const getThreadColorsOptions = (
  threads: PRProductExtraColors,
  threadColor: string | undefined
): number[][] => {
  const rgbThreads: number[][] = [];

  threads.forEach((item) => {
    if (threadColor === item.color) {
      const rgbThread = hexToRgb(item.color);

      rgbThreads.push(rgbThread);
    }
  });

  return rgbThreads;
};

type GetTemplateImageUrl = {
  PRProductByType: GetPRProductByTypeV2_getPRProductByTypeV2 | undefined;
  selectedColorTitle: string;
  colorOptions: PRProductColors;
  placement: string;
};

export const getTemplateImageUrl = ({
  PRProductByType,
  selectedColorTitle,
  colorOptions,
  placement,
}: GetTemplateImageUrl): string | null => {
  const selectedVariantId =
    colorOptions.find((item) => item.title === selectedColorTitle)?.id || null;
  const variantTemplates =
    PRProductByType?.data.templates.variant_mapping?.find(
      (item) => item?.variant_id === selectedVariantId
    )?.templates || [];
  const variantTemplateId =
    variantTemplates.find((item) => item?.placement === placement)
      ?.template_id || null;
  const backgroundImage =
    PRProductByType?.data.templates.templates?.find(
      (item) => item?.template_id === variantTemplateId
    )?.image_url || null;

  return backgroundImage;
};

type variants =
  | (
      | GetPRProductByTypeV2_getPRProductByTypeV2_data_product_variants
      | GetMerchProducts_getMerchProducts_entities_merchProductVariants
      | null
    )[]
  | null
  | undefined;

export const getVariantsByColor = (
  productVariants: variants,
  pickedColor?: string
): variants => {
  const variants =
    productVariants?.filter(
      (item) => item?.color && pickedColor === item.color
    ) || productVariants?.slice();

  return variants;
};

export const computeMerchBackgroundColor = (
  colorCode1: string,
  colorCode2?: string | null
): {
  background: string;
} => {
  // fix 'White' color because the "printful" send for us
  // '#e2e3de' and '#ebeaef' for hats
  //  which is a wrong colors and we rewrite them below
  const primaryColor =
    colorCode1 === '#e2e3de' || colorCode1 === '#ebeaef' ? '#fff' : colorCode1;
  const background = {
    ...(colorCode2
      ? {
          background: `linear-gradient(90deg, ${primaryColor} 50%, ${colorCode2} 50%)`,
        }
      : {
          background: primaryColor,
        }),
  };

  return background;
};

export const sortMerchProductVariantsByLowestPriceAndColor = <
  T extends MerchProductVariantsType
>(
  merchProductVariants: T[] = []
): T[] => {
  const sortVariants = merchProductVariants
    ?.slice()
    ?.sort((a, b) => {
      return b.color.localeCompare(a.color);
    })
    ?.sort((a, b) => {
      return a.price - b.price;
    });

  return sortVariants;
};

export const sortMerchProductVariantsByMainCustomImages = <
  T extends MerchProductVariantsType
>(
  merchProductVariants: T[] = []
): T[] => {
  const sortVariants = merchProductVariants
    .map((item) => {
      const sortedCustomImages = item.customImages?.slice().sort((a, b) => {
        return Number(b.isMainImage) - Number(a.isMainImage);
      });

      return {
        ...item,
        customImages: sortedCustomImages,
      };
    })
    .sort((a, b) => {
      const aHasCustomImages = !!a.customImages?.length;
      const bHasCustomImages = !!b.customImages?.length;

      return Number(bHasCustomImages) - Number(aHasCustomImages);
    })
    .sort((a, b) => {
      const isAMainImageExist =
        a.customImages?.some((item) => item.isMainImage) || false;
      const isBMainImageExist =
        b.customImages?.some((item) => item.isMainImage) || false;

      return Number(isBMainImageExist) - Number(isAMainImageExist);
    });

  return sortVariants;
};

export const createMerchTypesModal = [
  {
    id: 1,
    title: 'T SHIRTS',
    type: MerchType.TShirt,
  },
  {
    id: 2,
    title: 'HOODIES',
    type: MerchType.Hoodie,
  },
  {
    id: 3,
    title: 'JOGGERS',
    type: MerchType.Joggers,
  },
  {
    id: 4,
    title: 'COMPRESSION SHIRTS',
    type: MerchType.RashGuard,
  },
  {
    id: 5,
    title: 'HATS',
    type: MerchType.Hat,
  },
];

export const subOrderStatuses = [
  MerchOrderStatus.Canceled,
  MerchOrderStatus.Completed,
  MerchOrderStatus.Processing,
  MerchOrderStatus.Pending,
];

export const auctionStatuses = [
  AuctionStatus.Live,
  AuctionStatus.Scheduled,
  AuctionStatus.Finished,
];

export const getPrintfulVariantIds = (
  prProductData: GetPRProductByTypeV2 | undefined,
  gender: Gender,
  colorTitle: string
): number[] => {
  const genderVariant = prProductData?.getPRProductByTypeV2.find(
    (type) => type.gender === gender
  );
  const variants: number[] =
    genderVariant?.data.product.variants?.reduce((acc, value) => {
      if (value?.id && value?.color && colorTitle === value?.color) {
        acc.push(value.id);
      }

      return acc;
    }, [] as number[]) || [];

  return variants;
};

export const getCommonInput = (
  buildData: BuildData[],
  prProductData: GetPRProductByTypeV2 | undefined,
  type: MerchType,
  title: string,
  profit: string,
  keys: PresignedUrlResultItem[],
  hashtag: HashtagInput[]
) => {
  const customImageSets: MerchProductCustomImageSetInput[] = [];
  const printFileSets: MerchProductPrintFileSetInput[] = [];
  const variantSets: MerchProductVariantSet[] = [];
  const allImagesBase64: string[] = [];
  const hashtagInputs = formatHashtagInput(hashtag);

  buildData.forEach((item, index) => {
    const updatedGender = item.age === Age.Youth ? Gender.Unisex : item.gender;

    const variantIds = getPrintfulVariantIds(
      prProductData,
      updatedGender,
      item.colorTitle
    );

    if (type === MerchType.RashGuard) {
      variantSets.push({
        gender: updatedGender,
        age: item.age,
        variantIds,
        stitches: item.extraColor || '',
      });
    } else if (type === MerchType.Hat) {
      variantSets.push({
        gender: updatedGender,
        age: item.age,
        variantIds,
        threads: [item.extraColor || ''],
      });
    } else {
      variantSets.push({
        gender: updatedGender,
        age: item.age,
        variantIds,
      });
    }

    const printFiles: VariantPrintFileInput[] = [];

    item.printPlacements.forEach((placement) => {
      allImagesBase64.push(item.printFiles[placement]?.pngFromCanvas || '');
      printFiles.push({
        key:
          item.printFiles[placement]?.uploadedAreaKey ??
          (keys.shift()?.key || ''),
        placementType: placement,
        printFilePlacements: [
          {
            angle: item.printFiles[placement]?.angle || 0,
            left: item.printFiles[placement]?.left || 0,
            printImageId: item.printImages[placement]?.id || '',
            rotatedLeft: item.printFiles[placement]?.rotatedLeft || 0,
            rotatedTop: item.printFiles[placement]?.rotatedTop || 0,
            scaleX: item.printFiles[placement]?.scaleX || 0,
            scaleY: item.printFiles[placement]?.scaleY || 0,
            top: item.printFiles[placement]?.top || 0,
          },
        ],
      });
    });

    printFileSets.push({
      variantSetIndices: [index],
      printFiles,
    });

    if (item.customImages && item.customImages.length) {
      const customImages: MerchCustomImageV2Input[] = [];

      item.customImages.forEach((image) => {
        customImages.push({
          isMain: image.isMainImage,
          key: image.imageFileKey,
        });
      });

      customImageSets.push({
        customImages: customImages,
        variantSetIndices: [index],
      });
    }
  });

  return {
    title,
    type,
    requestedProfit: +(+profit).toFixed(2),
    printFileSets,
    variantSets,
    customImageSets,
    hashtagInputs,
  };
};

export const prepareBuildData = (
  type: MerchType,
  buildData: BuildData[]
): BuildData[] => {
  // we don't duplicate rashguard or hat data
  const isDuplicateProductType: boolean =
    type !== MerchType.RashGuard && type !== MerchType.Hat;

  const hasMale = !!buildData.find((item) => item.gender === Gender.Male);
  const hasFemale = !!buildData.find((item) => item.gender === Gender.Female);

  // duplicate only if there is a male/female gender missing and merch type is valid
  const shouldDuplicate: boolean =
    isDuplicateProductType &&
    ((!hasMale && hasFemale) || (!hasFemale && hasMale));

  if (shouldDuplicate) {
    const updatedBuildData: BuildData[] = [];
    buildData.forEach((item) => {
      updatedBuildData.push(item);
      // we copy hoodies, shirts and joggers depending on what gender is missing
      // we dont copy the youth shirts variants
      if (item.gender === Gender.Male) {
        updatedBuildData.push({ ...item, gender: Gender.Female });
      }
      if (item.gender === Gender.Female) {
        updatedBuildData.push({ ...item, gender: Gender.Male });
      }
    });
    return updatedBuildData;
  } else {
    return buildData;
  }
};

type UniqueVariant = {
  color: string;
  colorGroupId: number;
  gender: Gender;
  age: Age;
};

export const convertMerchDataIntoBuildData = (
  variants: GetMyMerchProductV2_getMerchProduct_merchProductVariants[]
): BuildData[] => {
  // go trough the items, extract color+gender+age+colorGroupId array
  // then go trough the array and create buildData
  const uniqueVariants: UniqueVariant[] = [];
  const buildData: BuildData[] = [];

  variants?.forEach((variant) => {
    if (variant.colorGroupId && variant.gender && variant.age) {
      const hasItem = !!uniqueVariants.find((item) => {
        return (
          variant.color === item.color &&
          variant.colorGroupId === item.colorGroupId &&
          variant.gender === item.gender &&
          variant.age === item.age
        );
      });
      if (!hasItem) {
        // add this version to the uniqueVariants array so we have it for future comparisons
        uniqueVariants.push({
          color: variant.color,
          colorGroupId: variant.colorGroupId,
          gender: variant.gender,
          age: variant.age,
        });
        const extraColor = variant.threads || variant.stitches || undefined;

        const placements: PlacementTypes[] = [];
        let printImages: SelectedPrintImages = {};
        let printFiles: PrintFiles = {};

        variant.files.forEach((file) => {
          const placement = file.placementType as PlacementTypes;
          placements.push(placement);

          // we always have only one image
          const merchProductImage = file.merchProductPrintfilePlacements[0];

          const printImage: GetMyPrintImages_getMyPrintImages = {
            __typename: 'PrintImage',
            id: merchProductImage.printImageId,
            imageURL: null,
          };
          printImages = {
            ...printImages,
            [placement]: printImage,
          };

          const printCoordinates: PrintCoordinates = {
            pngFromCanvas: undefined,
            uploadedAreaKey: file.imageFileKey,
            angle: merchProductImage.angle || 0,
            left: merchProductImage.left || 0,
            rotatedLeft: merchProductImage.rotatedLeft || 0,
            rotatedTop: merchProductImage.rotatedTop || 0,
            scaleX: merchProductImage.scaleX || 0,
            scaleY: merchProductImage.scaleY || 0,
            top: merchProductImage.top || 0,
            width: 0,
            height: 0,
          };

          printFiles = { ...printFiles, [placement]: printCoordinates };
        });

        const customImages: CustomImage[] = [];

        variant.customImages?.forEach((image) => {
          customImages.push({
            imageFileKey: image.imageFileKey || '',
            isMainImage: !!image.isMainImage,
          });
        });

        const hasMainCustomImage = variant.customImages?.find(
          (image) => image.isMainImage === true
        );

        if (!hasMainCustomImage && customImages.length) {
          customImages[0].isMainImage = true;
        }

        buildData.push({
          gender: variant.age === Age.Adult ? variant.gender : Gender.Youth,
          age: variant.age,
          colorTitle: variant.color,
          extraColor: extraColor
            ? extraColor.replace(`["`, '').replace(`"]`, '')
            : undefined,
          printImages: printImages,
          printPlacements: placements,
          hasError: false,
          printFiles: printFiles,
          customImages: customImages,
        });
      }
    }
  });

  return buildData;
};

export const formatHashtagInput = (hashtags) => {
  if (hashtags.length) {
    return hashtags.map((hashtag) => ({ name: hashtag }));
  } else {
    return null;
  }
};
